import React, {Component} from 'react';
//import {MiniButton} from "./SuperLayout"
import { Icon, Tooltip } from "@blueprintjs/core";
import { Tooltip2 } from "@blueprintjs/popover2";

const LABEL_STYLE = {
    color: "green"
};

const FIELD_STYLE = {
};

const OPER_STYLE = {
    fontWeight: "bold",
};

const VALUE_STYLE = {
    color: "blue"
};

const SIMPLE_STYLE = {
    border: "1px solid #777",
    padding: "4px",
    borderRadius: "4px",
    marginBottom: "4px",
    marginTop: "4px",
    backgroundColor: "rgb(245, 245, 245)"
};

const AND_STYLE = {
    border: "2px solid #777",
    padding: "9px",
    borderRadius: "8px",
    marginBottom: "8px",
    marginTop: "8px",
    backgroundColor: "rgb(226, 251, 255)"
};

const OR_STYLE = {
    ...AND_STYLE,
    backgroundColor: "rgb(216, 247, 218)"
};

const TITLE_STYLE = {
    fontSize: "15px"
}

function MiniButton( props ) {
    return <Tooltip2 autoFocus={false} content={props.tooltip}  >
        <button className="dh-mini-button" onClick={props.onClick} style={props.style}>
            <Icon icon={props.icon} />
        </button>
    </Tooltip2>;
}

export class ConditionElem extends Component {
    render() {
        let desc = this.props.desc;
        return this.renderElem( desc.value, "z" );
    }

    renderElem( value, key ) {
        if ( !value ) value = {};
        //  return <span key={key}>Пустое условие</span>;

        let label = value.label && <span style={LABEL_STYLE}>{value.label}: </span>;
        let edit;

        if (value.condType === "simple") {
            if ( this.props.desc.editMode ) {
                edit = <span>
                    <MiniButton icon="edit" tooltip="Редактировать" onClick={()=>this.editValue(value)} />
                    <MiniButton icon="cross" tooltip="Удалить" onClick={()=>this.deleteValue(value)} />
                </span>;
            }
            return <div key={key} style={SIMPLE_STYLE}>
                {edit}
                {label}
                <span style={FIELD_STYLE}>{value.fieldDesc || value.field} </span>
                <span style={OPER_STYLE}>{value.operatorDesc || value.operator} </span>
                <span style={VALUE_STYLE}>{value.value}</span>
            </div>
        }

        let edit2;
        if ( this.props.desc.editMode ) {
            edit = <span>
                <MiniButton icon="edit" tooltip="Редактировать" onClick={()=>this.editValue(value)} />
                <MiniButton icon="cross" tooltip="Удалить" onClick={()=>this.deleteValue(value)} />
            </span>;
            edit2 = <span>
                <MiniButton icon="plus" tooltip="Добавить" onClick={()=>this.addValue(value)} />
            </span>;
        }

        let subnodes = this.renderSubnodes(value.subnodes);
        if (value.condType === "and") {
            return <div key={key} style={AND_STYLE}>
                <div style={TITLE_STYLE}>{edit}{label}Объединение <b>И</b></div>
                {subnodes}
                {edit2}
            </div>;
        }

        if (value.condType === "or") {
            return <div key={key} style={OR_STYLE}>
                <div style={TITLE_STYLE}>{edit}{label}Пересечение <b>ИЛИ</b></div>
                {subnodes}
                {edit2}
            </div>;
        }

        if ( this.props.desc.editMode ) {
            return <span>
                <MiniButton icon="plus" tooltip="Добавить" onClick={()=>this.addValue(value)} />
                Создать условие
            </span>;
        }
        else 
            return <span>Пустое условие</span>;
    }

    renderSubnodes( subnodes ) {
        if ( !subnodes || subnodes.length == 0 )
            return <span>Пустой список</span>

        let res = [];
        let i = 0;
        for ( let node of subnodes ) {
            res.push( this.renderElem( node, node.id || i++ ) );
        }
        return res;
    }

    editValue( value ) {
        let options = {
            mode: "view_inline",
            target: "dialog",
            doc: value,
            editMode: true,
            typeName: this.props.desc.typeName,
            onDialogOk: model=>{
                model.onClose();
                this.afterEditValue(value, model.getDoc());
            }
        }
        window.app.showView( options );
    }

    afterEditValue( value, newValue ) {
        let root = this.props.desc.value;
        if ( root === value ) {
            this.notifyChange( newValue );
            return;
        }

        root = this.clone( root );
        let base = this.findBase( root, value );
        console.log( "After edit value", root, base, value );
        if ( !base ) return;
        
        base.subnodes = base.subnodes.map( n => (n.id == value.id ? newValue : n) );
        this.notifyChange( root );
    }

    deleteValue( value ) {
        let root = this.props.desc.value;
        if ( root === value ) {
            this.notifyChange( null );
            return;
        }

        root = this.clone( root );
        let base = this.findBase( root, value );
        if ( !base ) return;
        
        if ( !base.subnodes )
            console.log( "Invalid base: ", base );

        base.subnodes = base.subnodes.filter( n => n.id !== value.id );
        this.notifyChange( root );
    }

    addValue( value ) {
        let options = {
            mode: "view_inline",
            target: "dialog",
            doc: { id: String(Date.now()) },
            editMode: true,
            typeName: this.props.desc.typeName,
            onDialogOk: model=>{
                model.onClose();
                this.afterAddValue(value, model.getDoc());
            }
        }
        window.app.showView( options );
    }

    afterAddValue( value, newValue ) {
        let root = this.props.desc.value;
        if ( root == null || !(root.condType in {and:1,or:1})) {
            this.notifyChange( newValue );
            return;
        }

        if ( root === value ) {
            let newRoot = { ...value };
            if ( newRoot.subnodes )
                newRoot.subnodes = [ ...newRoot.subnodes, newValue ];
            else
                newRoot.subnodes = [ newValue ];
            this.notifyChange( newRoot );
            return;
        }

        root = this.clone( root );
        let base = this.findValue( root, value );
        console.log( "After add value", root, base, value );
        if ( !base ) return;

        base.subnodes.push( newValue );
        this.notifyChange( root );
    }

    clone( value ) {
        let res = { ...value };
        if ( res.subnodes ) {
            res.subnodes = res.subnodes.map( v => this.clone( v ) );
        }
        return res;
    }

    findBase( base, value ) {
        for ( let subnode of base.subnodes ) {
            if ( subnode === value || subnode.id === value.id ) {
                return base;
            }
            if ( subnode.subnodes ) {
                let res = this.findBase( subnode, value );
                if ( res ) return res;
            }
        }
    }

    findValue( base, value ) {
        if ( base.id && base.id === value.id )
            return base;

        for ( let subnode of base.subnodes ) {
            if ( subnode === value || subnode.id === value.id ) {
                return subnode;
            }
            if ( subnode.subnodes ) {
                let res = this.findValue( subnode, value );
                if ( res ) return res;
            }
        }
    }

    notifyChange( value ) {
        console.log( "Notify change: ", value );
        let desc = this.props.desc;
        window.dispatch( {
            actionType: "fieldChange",
            forPage: desc.pageId,
            field: desc.name,
            value,
            desc
        } );
    }
}
